import SpraypaintBaseNonPersistedDirtyTracking from 'src/models/baseModels/SpraypaintBaseNonPersistedDirtyTracking';
import { SpraypaintBase } from 'spraypaint';
import { SaveOptions } from 'spraypaint/lib-esm/model';


export default class SpraypaintBaseSaveDeleteStatus extends SpraypaintBaseNonPersistedDirtyTracking {
  isDeleting: boolean;

  // will not be set to false after destroy is successful.
  // This may be used to show state until parent query has been reloaded
  isDeletingStarted: boolean;
  isSaving: boolean;
  // will not be set to false after save is successful.
  // This may be used to show state until parent query has been reloaded
  isSavingStarted: boolean;

  destroy(): Promise<boolean> {
    this.isDeleting = true;
    this.isDeletingStarted = true;
    const promise = super.destroy();
    promise.finally(() => this.isDeleting = false);
    return promise;
  }

  save<I extends SpraypaintBase>(options?: SaveOptions<I>): Promise<boolean> {
    this.isSaving = true;
    this.isSavingStarted = true;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const promise = super.save(options);
    promise.finally(() => this.isSaving = false);
    return promise;
  }
}
