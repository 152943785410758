import SpraypaintBaseSaveDeleteStatus from 'src/models/baseModels/SpraypaintBaseSaveDeleteStatus';
import { isArray, isEmpty } from 'lodash';
import { Attr, SpraypaintBase } from 'spraypaint';
import { SaveOptions } from 'spraypaint/lib-esm/model';

export default class SpraypaintBaseDetachRelationsWhenEmpty extends SpraypaintBaseSaveDeleteStatus {

  detachRelationsWhenEmptyByName: string[] = [];
  /**
   * hack to allow detaching all relations since spraypaint does not send an empty array
   * @see CommonRessourceRequest.php -> after()
   * https://github.com/graphiti-api/spraypaint.js/issues/81
   */

  @Attr() private detachRelationsByName: string[];

  detachWhenEmpty(names: string | string[]): this {
    if (isArray(names)) {
      for (const name of names) {
        this.detachRelationsWhenEmptyByName.push(name);
      }
    } else {
      this.detachRelationsWhenEmptyByName.push(names);
    }
    return this;
  }

  save<I extends SpraypaintBase>(options?: SaveOptions<I>): Promise<boolean> {
    this.checkDetachRelationsWhenEmpty();
    return super.save(options);
  }

  private checkDetachRelationsWhenEmpty() {
    for (const relationName of this.detachRelationsWhenEmptyByName) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const relation = this[relationName];
      if (isEmpty(relation)) {
        if (this.detachRelationsByName === undefined) {
          this.detachRelationsByName = [];
        }
        if(!this.detachRelationsByName.includes(relationName)) {
            this.detachRelationsByName.push(relationName);
        }
      }
    }
  }

}
