import { Attr, BelongsTo, Model } from 'spraypaint';
import BaseModel from 'src/models/BaseModel';
import TeamLedger from 'src/models/TeamLedger';
import Person from 'src/models/Person';
import TeamMember from 'src/models/TeamMember';
import TeamLedgerFine from 'src/models/TeamLedgerFine';
import TeamLedgerDues from 'src/models/TeamLedgerDues';
import { Money } from 'src/helpers/money';
import TeamLedgerClaimStatusType = App.Types.TeamLedgerClaimStatusType;
import { isEmpty } from 'lodash';
import { QSelectOption } from 'quasar';
import TeamLedgerClaimable from 'src/models/abstracts/TeamLedgerClaimable';

export const claimStatusIcon: Record<TeamLedgerClaimStatusType, string> = {
    fulfilled: 'o_check_circle',
    exempt: 'remove_circle_outline',
    unfulfilled: 'o_circle',
    unfulfilled_overdue: 'o_circle',
};

export const claimStatusIconColor: Record<TeamLedgerClaimStatusType, string> = {
    fulfilled: 'positive',
    exempt: 'blue-grey-13',
    unfulfilled: 'blue-grey-13',
    unfulfilled_overdue: 'negative',
};

type ClaimStatusOptionWithIconAndColor = QSelectOption<TeamLedgerClaimStatusType> & {
    icon: (typeof claimStatusIcon)[TeamLedgerClaimStatusType],
    iconColor: (typeof claimStatusIconColor)[TeamLedgerClaimStatusType],
}

export const TeamLedgerClaimStatusOptions: ClaimStatusOptionWithIconAndColor[] = [
    {
        value: 'fulfilled',
        label: 'Bezahlt',
        icon: claimStatusIcon.fulfilled,
        iconColor: claimStatusIconColor.fulfilled
    }, {
        value: 'exempt',
        label: 'Befreit',
        icon: claimStatusIcon.exempt,
        iconColor: claimStatusIconColor.exempt
    }, {
        value: 'unfulfilled',
        label: 'Offen',
        icon: claimStatusIcon.unfulfilled,
        iconColor: claimStatusIconColor.unfulfilled
    }, {
        value: 'unfulfilled_overdue',
        label: 'Überfällig',
        icon: claimStatusIcon.unfulfilled_overdue,
        iconColor: claimStatusIconColor.unfulfilled_overdue
    }
];

@Model()
export default class TeamLedgerClaim<ClaimableType extends TeamLedgerClaimable<ClaimableType>> extends BaseModel {
    static jsonapiType = 'teamLedgerClaims';

    @Attr() id: string;
    @Attr() exempt: boolean;
    @Attr() title: string;
    @Attr() private dueDate: string | null = null;
    @Attr() amount: Money | null;
    @Attr() item: string | null;
    @Attr() private fulfilledAt: string | null = null;
    @Attr() status: TeamLedgerClaimStatusType;
    @BelongsTo('teamMembers') teamMember: TeamMember;
    @BelongsTo('persons') readonly author: Person;
    @BelongsTo('teamLedgers') ledger: TeamLedger;
    @BelongsTo() claimable: ClaimableType | null;

    static createForLedger<ClaimableType extends TeamLedgerClaimable<ClaimableType>>(ledger: TeamLedger): TeamLedgerClaim<ClaimableType>{
        const instance = new TeamLedgerClaim<ClaimableType>({ledger})
        instance.dueDate = instance.convertDateTimeToLocalizedString(new Date(), instance.dateMaskAPI)
        return instance
    }

    /**
     * for dues claims that are recurring, the key represents
     * a value that is the same for all members
     */
    getKey(): string {
        return this.dueDateFormatted + this.claimable?.id
    }

    hasRelatedFine(): boolean {
        return this.claimable !== null && this.claimable.isType(TeamLedgerFine.jsonapiType);
    }

    hasRelatedDues(): boolean {
        return this.claimable !== null && this.claimable.isType(TeamLedgerDues.jsonapiType);
    }

    get dueDateFormatted(): string {
        return this.convertDateStringFromApi(this.dueDate);
    }

    set dueDateFormatted(value: string | null) {
        this.dueDate = this.convertDateStringToApi(value);
    }

    getDueDateFormatted(format: string): string {
        return this.convertDateStringFromApi(this.dueDate, format);
    }

    getClaimText() {
        return this.amount ? this.amount.formatted : this.item
    }

    isStatus(status: TeamLedgerClaimStatusType) {
        return this.status === status
    }

    getStatusInfo()    {
        return TeamLedgerClaimStatusOptions
            .find((option) => option.value === this.status)
    }

    isUnfulfilled(): boolean {
        return this.isStatus('unfulfilled') || this.isStatus('unfulfilled_overdue');
    }

    isMoneyClaim(): boolean {
        return !isEmpty(this.amount)
    }

    isItemClaim(): boolean {
        return isEmpty(this.amount)
    }
}
