import { Attr, BelongsTo, Model } from 'spraypaint';
import Person from 'src/models/Person';
import BaseModelWithTimestamps from 'src/models/BaseModelWithTimestamps';

@Model()
export default class User extends BaseModelWithTimestamps {
  static jsonapiType = 'users';

  @Attr() id: string;
  @Attr() email: string;
  @Attr() readonly hasVerifiedEmail: boolean;
  @Attr() readonly isProblemProvider: boolean;
  @BelongsTo(Person) person: Person;
  @Attr() readonly origin: string;
  @Attr() readonly competition: string;

}
