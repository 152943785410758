import { Attr, BelongsTo, HasMany, HasOne, Model } from 'spraypaint';
import Team from 'src/models/Team';
import Person from 'src/models/Person';
import TeamRole from 'src/models/TeamRole';
import Invite, { Invitable } from 'src/models/Invite';
import BaseModelWithTimestamps from 'src/models/BaseModelWithTimestamps';

@Model()
export default class TeamMember extends BaseModelWithTimestamps implements Invitable<TeamMember> {
  static jsonapiType = 'teamMembers';

  @Attr() id: string;
  @Attr() name: '';
  @Attr() isRegistered: boolean;
  @Attr() isTest: boolean;
  @Attr() deletedAt: string | null = null;
  @BelongsTo(Team) team: Team;
  @BelongsTo() person?: Person;
  @BelongsTo(TeamRole) statusRole: TeamRole;
  @HasMany() roles: TeamRole[] = [];
  @HasOne() invite?: Invite<TeamMember>;

  hasPersistedInvite(): boolean {
    return this.invite !== undefined && this.invite.isPersisted;
  }

  isAvailableForTeamInvite(): boolean {
    return !this.isRegistered && this.statusRole.name !== 'manager';
  }
}
