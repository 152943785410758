import { Attr, Model } from 'spraypaint';
import TeamLedger from 'src/models/TeamLedger';
import { datetime, RRule } from 'rrule';
import TeamLedgerClaimable from 'src/models/abstracts/TeamLedgerClaimable';
import { date } from 'quasar';

@Model()
export default // @ts-expect-error TS2417: Class static side typeof TeamLedgerDues incorrectly extends base class static side typeof TeamLedgerClaimable
class TeamLedgerDues extends TeamLedgerClaimable<TeamLedgerDues> {
    static jsonapiType = 'teamLedgerDues';

    @Attr() rruleString: string | null = null;

    static createFollowUp(
        parentClaimable: TeamLedgerDues,
        ledger: TeamLedger
    ): TeamLedgerDues {
        const instance = super.createFollowUp<TeamLedgerDues>(parentClaimable, ledger);
        instance.rruleString = parentClaimable.rruleString;
        return instance;
    }

    getDateStart(): Date | null {
        if (this.dateBegin === null) {
            return null;
        }
        const dateBegin = this.getDateObjectFromMask(this.dateBegin);
        return datetime(dateBegin.getFullYear(), dateBegin.getMonth() + 1, dateBegin.getDate());
    }

    private getDateEnd(): Date | null {
        if(this.isRecurring()) {
            const options = this.getRRule()?.options
            if(options?.until) {
                return options.until
            }
        }
        return null
    }

    isStarted() {
        const startDate = this.getDateStart()

        if (startDate !== null) {
            return date.getDateDiff(new Date(), startDate) > 0
        }
        return false
    }

    isEnded() {
        const endDate = this.getDateEnd() ?? this.getDateStart()

        if (endDate !== null) {
            return date.getDateDiff(new Date(), endDate) > 0
        }
        return false
    }

    isRecurring(): boolean {
        return this.rruleString !== null;
    }

    getRRule() {
        return this.rruleString !== null ? RRule.fromString(this.rruleString) : null;
    }

}
