import BaseModel from 'src/models/BaseModel';
import { Attr, HasMany, Model } from 'spraypaint';
import TeamPermission from 'src/models/TeamPermission';
import TeamRoleType = App.Types.TeamRoleType;

@Model()
export default class TeamRole extends BaseModel {
  static jsonapiType = 'teamRoles';
  static statusRoleOrder: TeamRoleType[] = [
    'manager',
    'member',
    'inactive',
  ];
  @Attr() id: string;
  @Attr() name: TeamRoleType;
  @HasMany() permissions: TeamPermission[] = [];

  static getDisplayName(roleName: TeamRoleType, count = 1) {
    switch (roleName) {
      case 'manager':
        return 'Leitung';
      case 'member':
        return count === 1 ? 'Mitglied' : 'Mitglieder';
      case 'inactive':
        return count === 1 ? 'Inaktiv' : 'Inaktive';
      case 'treasurer':
        return 'Kassenwart';
    }
  }

  getName() {
    return TeamRole.getDisplayName(this.name);
  }

  isStatusRole() {
      return TeamRole.statusRoleOrder.includes(this.name)
  }
}
