import { Attr, BelongsTo, HasMany, Model } from 'spraypaint';
import BaseModel from 'src/models/BaseModel';
import Team from 'src/models/Team';
import { Money } from 'src/helpers/money';
import TeamLedgerFine from 'src/models/TeamLedgerFine';
import TeamLedgerClaim from 'src/models/TeamLedgerClaim';
import TeamLedgerDues from 'src/models/TeamLedgerDues';
import TeamMember from 'src/models/TeamMember';

export type TeamLedgerMemberStatus = {
    totalClaimBalance: Money
    claimBalance: Money
    futureClaimBalance?: Money
    availableBalance: Money
    creditBalance: Money
    dueItemClaimIds: string[]
}

@Model()
export default class TeamLedger extends BaseModel {
    static jsonapiType = 'teamLedgers';

    @Attr() id: string;
    @BelongsTo() team: Team;

    @Attr() readonly totalClaimBalance: Money;
    @Attr() readonly availableBalance: Money;
    @Attr() readonly availableBalanceFromMembers: Money;
    @Attr() readonly claimBalance: Money;
    @Attr() readonly futureClaimBalance: Money;
    @Attr() readonly balance: Money;
    @Attr() readonly creditBalance: Money;
    @Attr() readonly nonMemberBalance: Money;
    @Attr() readonly nonMemberSumIn: Money;
    @Attr() readonly nonMemberSumOut: Money;

    @Attr() private readonly memberStatus: TeamLedgerMemberStatus[];
    @HasMany('teamMembers') readonly formerMembers: TeamMember[];

    @HasMany('teamLedgerFines') fines: TeamLedgerFine[] = []
    @HasMany(TeamLedgerClaim) dueItemClaims: TeamLedgerClaim<TeamLedgerDues | TeamLedgerFine>[] = []

    static noMemberText = 'von extern'

    getActiveMemberStatus(memberId: string): TeamLedgerMemberStatus {
        return this.memberStatus['active'][memberId]
    }
    getFormerMemberStatus(memberId: string): TeamLedgerMemberStatus {
        return this.memberStatus['former'][memberId]
    }
}
