export enum ROUTE_NAMES {
    NOT_FOUND = 'NOT_FOUND',
    IMPRESSUM = 'IMPRESSUM',
    PRIVACY = 'PRIVACY',
    CONTACT = 'CONTACT',
    ADMIN_STATS = 'ADMIN_STATS',
    LOGIN = 'LOGIN',
    REGISTRATION = 'REGISTRATION',
    ADD_TO_WAIT_LIST = 'ADD_TO_WAIT_LIST',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    INVITE = 'INVITE',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',

    HOME = 'HOME',

    TEAMS = 'TEAMS',
    TEAM = 'TEAM',
    TEAMS_CREATE = 'TEAMS_CREATE',

    EVENTS = 'EVENTS',
    EVENTS_CREATE = 'EVENTS_CREATE',
    EVENTS_EDIT = 'EVENTS_EDIT',
    EVENTS_COPY = 'EVENTS_COPY',
    EVENTS_SHOW = 'EVENTS_SHOW',

    MY_PROFILE = 'MY_PROFILE',

    ABSENCES = 'ABSENCES',
    ABSENCE_CREATE = 'ABSENCE_CREATE',
    ABSENCE_EDIT = 'ABSENCE_EDIT',

    STATS = 'STATS',

    LEDGERS = 'LEDGERS',
    LEDGER = 'LEDGER',
}
