import { defineStore } from 'pinia';
import User from 'src/models/User';
import Person from 'src/models/Person';
import Team from 'src/models/Team';
import { isEmpty } from 'lodash';
import TeamMember from 'src/models/TeamMember';
import TeamPermissionType = App.Types.TeamPermissionType;
import FeatureType = App.Types.FeatureType;

export interface AuthState {
  token?: string;
  userId?: string;
  user?: User;
  person?: Person;
  fcmToken?: string;
  installationId?: string;
}

// TODO: this should be in here
export type LoginResponse = {
  token: string
  userId: string
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    token: undefined,
    userId: undefined,
    user: undefined,
    person: undefined,
    fcmToken: undefined,
    installationId: undefined,
  }),

  actions: {
    async initializeUserSession(token: string, userId: string) {
      this.token = token;
      this.userId = userId;

      await this.fetchUserData();
    },

    async fetchUserData() {
      if (this.userId) {
        await User.includes('person').find(this.userId)
          .then(data => this.user = data.data)
          .catch(() => {
            this.logout();
            window.location.reload();
          });
        await this.loadPermissions();
      }
    },

    async loadPermissions() {
      if (this.user) {
        this.person = await Person
          .selectExtra(['teamPermissions', 'activeFeatures'])
          .includes({ teamMembers: ['team', 'statusRole'] })
          .find(this.user.person.id)
          .then(data => data.data);
      }
    },

    async setFcmToken(fcmToken: string) {
      this.fcmToken = fcmToken;
    },

    async setUniqueId(installationId: string) {
      this.installationId = installationId;
    },

    /**
     * !! ATTENTION !!do not use this method within a component
     * use useAuth() composable instead
     */
    async logout() {
      await this.$reset();
    },

    hasPermissionForTeam(team: Team, permissionName: TeamPermissionType): boolean {
      return this.person instanceof Person
        && this.person.teamPermissions !== null
        && team.id in this.person.teamPermissions
        && this.person.teamPermissions[team.id].includes(permissionName);
    },
    hasPermissionForAnyTeam(permissionName: TeamPermissionType): boolean {
      if (this.person instanceof Person && this.person.teamPermissions !== null) {
        for (const teamPermissions of Object.values(this.person.teamPermissions)) {
          if (teamPermissions.includes(permissionName)) {
            return true;
          }
        }
      }
      return false;
    },
    hasFeature(feature: FeatureType): boolean {
      return this.person instanceof Person
        && !isEmpty(this.person.activeFeatures)
        && this.person.activeFeatures.includes(feature);
    },
    isOwnMember(member: TeamMember) {
        if(member.person) {
            return member.person?.id === this.person?.id;
        } else {
            return !!this.person?.teamMembers.find(teamMember => teamMember.id === member.id);
        }
    },
  },

  getters: {
    loggedIn(state): boolean {
      return state.token !== undefined;
    },

    emailVerified(state): boolean {
      return state.user !== undefined && state.user.hasVerifiedEmail;
    },

    // ---------------------------
    // Helper functions for the UI
    // ---------------------------
    avatarName(state): string {
      if (!state.person) {
        return '';
      }
      return state.person.firstname;
    },

    avatarLetters(state): string {
      if (!state.person) {
        return '';
      }
      try {
        return (state.person.firstname.charAt(0) + state.person.lastname.charAt(0)).toUpperCase();
      } catch (error) {
        return '';
      }
    },

    fullName(state): string {
      if (!state.person) {
        return '';
      }
      return state.person.name;
    },
    teamsCount(state): number {
      return state.person ? state.person.teamsCount : 0;
    },
  },

  persist: {
    // afterRestore: ({store}) => {
    //   // reload persisted model since deserialized is a plain object
    //   // is now be loaded in App.vue # preFetch to load before app is started
    //   // store.fetchUserData()
    // }
  },
});
