import BaseModel from 'src/models/BaseModel';
import { Attr } from 'spraypaint';
import { defaultDateTimeMask } from 'src/i18n';


export default class BaseModelWithTimestamps extends BaseModel {

    @Attr() readonly createdAt: string;
    @Attr() readonly updatedAt: string;

    getCreatedAtFormatted(mask: string = defaultDateTimeMask) {
        return this.formatDateTimeLocalized(this.createdAt, mask);
    }

    getUpdatedAtFormatted(mask: string = defaultDateTimeMask) {
        return this.formatDateTimeLocalized(this.updatedAt, mask);
    }
}

