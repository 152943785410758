import { MiddlewareStack, Model } from 'spraypaint';
import { KeyCase } from 'spraypaint/lib-esm/model';
import { useAuthStore } from 'src/stores/auth';
import { date, QSelectOption, Quasar } from 'quasar';
import { defaultDateMask, defaultTimeMask } from 'src/i18n';
import SpraypaintBaseDetachRelationsWhenEmpty from 'src/models/baseModels/SpraypaintBaseDetachRelationsWhenEmpty';
import { isEqual } from 'lodash';
import { DirtyChecker } from 'spraypaint/lib/attribute';

export const dirtyChecker: DirtyChecker<never> = (prior: never, current: never) => !isEqual(prior, current);

export type SelectOptionWithIcon<T = string> = QSelectOption<T> & {
  icon: string
}

export type SelectOptionWithInfo<T = string> = QSelectOption<T> & {
  info: string
}

@Model()
export default class BaseModel extends SpraypaintBaseDetachRelationsWhenEmpty {
  static baseUrl = process.env.API_URL;
  static apiNamespace = '/v1';
  static keyCase = {
    server: 'camel',
    client: 'camel',
  } as KeyCase;
  protected dateMaskAPI = 'YYYY-MM-DD';
  protected timeMaskAPI = 'HH:mm';

  static stripHtml(html: string) {
    const temporalDivElement = document.createElement('div');
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  protected convertDateStringToApi(value: string | null) {
    return this.convertDateTimeStringToApi(value, defaultDateMask, this.dateMaskAPI);
  }

  protected convertDateStringFromApi(value: string | null, localMask = defaultDateMask): string {
    return this.convertDateTimeStringFromApi(value, localMask, this.dateMaskAPI);
  }

  protected convertTimeStringToApi(value: string | null) {
    return this.convertDateTimeStringToApi(value, defaultTimeMask, this.timeMaskAPI);
  }

  protected convertTimeStringFromApi(value: string | null, localMask = defaultTimeMask): string {
    return this.convertDateTimeStringFromApi(value, localMask, this.timeMaskAPI);
  }

  protected convertDateTimeStringToApi(value: string | null, localMask: string, apiMask: string) {
    return (value === null || value === '') ? null :
      this.convertDateTimeToLocalizedString(date.extractDate(value, localMask), apiMask);
  }

  protected convertDateTimeStringFromApi(value: string | null, localMask: string, apiMask: string) {
    return value === null ? '' :
      this.convertDateTimeToLocalizedString(this.getDateObjectFromMask(value, apiMask), localMask);
  }

  protected getDateObjectFromMask(value: string, mask: string = this.dateMaskAPI) {
    return date.extractDate(value, mask);
  }

  protected getTimeObjectFromMask(value: string, mask: string = this.timeMaskAPI) {
    return date.extractDate(value, mask);
  }

  protected convertDateTimeToLocalizedString(dateObject: Date, mask: string) {
    return this.formatDateTimeLocalized(dateObject, mask);
  }

  protected formatDateTimeLocalized(dateObject: Date | number | string | undefined, mask: string) {
    return date.formatDate(dateObject, mask, Quasar.lang.date);
  }
}

// add Token to requests if logged in
const middleware = new MiddlewareStack();
middleware.beforeFilters.push((url, options) => {
  // options.credentials = 'include'
  const authStore = useAuthStore();
  if (authStore.loggedIn) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    options.headers.Authorization = 'Bearer ' + authStore.token;
  }
});

middleware.afterFilters.push(async (response) => {
  if (response.status === 401) {
    await useAuthStore().logout();
    window.location.reload();
  }
});

BaseModel.middlewareStack = middleware;
