import BaseModel from 'src/models/BaseModel';
import { Attr, HasMany, HasOne, Model } from 'spraypaint';
import TeamMember from 'src/models/TeamMember';
import User from 'src/models/User';
import { uniq } from 'lodash';
import TeamPermissionType = App.Types.TeamPermissionType;
import FeatureType = App.Types.FeatureType;

type TeamPermissionNamesPerTeamId = Readonly<Record<string, TeamPermissionType[]>>;

@Model()
export default class Person extends BaseModel {
  static jsonapiType = 'persons';

  @Attr() id: string;
  @Attr() firstname: '';
  @Attr() lastname: '';
  @Attr() teamPermissions: TeamPermissionNamesPerTeamId = {};
  @Attr() activeFeatures: FeatureType[];
  @HasMany(TeamMember) teamMembers: TeamMember[];
  @HasOne() user: User;

  get teams() {
    return uniq(this.teamMembers.map(member => member.team));
  }

  get name() {
    return this.firstname + ' ' + this.lastname;
  }

  get teamsCount(): number {
    return this.teams.length;
  }
}
