import BaseModel from 'src/models/BaseModel';
import { Attr, BelongsTo } from 'spraypaint';
import { Money, MoneyClass } from 'src/helpers/money';
import TeamLedger from 'src/models/TeamLedger';
import { date } from 'quasar';
import { isEmpty } from 'lodash';

export default abstract class TeamLedgerClaimable<ClaimableType extends TeamLedgerClaimable<ClaimableType>> extends BaseModel{

    @Attr() title: string;
    @Attr() amount: Money | null;
    @Attr() item: string | null;
    @Attr() protected dateBegin: string | null = null;
    @Attr() deletedAt: string | null = null;
    @BelongsTo('teamLedgers') ledger: TeamLedger;
    @BelongsTo() parent: ClaimableType | null;

    static createForLedger<T extends TeamLedgerClaimable<T>>(
        this: new (args: Partial<TeamLedgerClaimable<T>>) => T,
        ledger: TeamLedger
    ){
        const instance = new this({ledger})
        instance.setDateBeginToday()
        return instance
    }

    static createFollowUp<T extends TeamLedgerClaimable<T>>(
        this: new (args: Partial<TeamLedgerClaimable<T>>) => T,
        parentClaimable: T,
        ledger: TeamLedger
    ): T {
        const instance = new this({ledger})
        instance.title = parentClaimable.title
        if(parentClaimable.amount) {
            instance.amount = MoneyClass.fromServer(parentClaimable.amount)
        } else {
            instance.item = parentClaimable.item
        }
        instance.parent = parentClaimable
        instance.setDateBeginToday()
        return instance
    }

    /**
     * do not create a follow-up but change original instead.
     * For this, some values have to be adjusted
     */
    removeParent(resetDateBegin = true) {
        if(this.parent) {
            this.id = this.parent.id
            if(resetDateBegin) {
                this.setDateBeginFromParent()
            }
            this.parent = null
        }
    }

    get dateBeginFormatted(): string {
        return this.convertDateStringFromApi(this.dateBegin);
    }

    set dateBeginFormatted(value: string | null) {
        this.dateBegin = this.convertDateStringToApi(value);
    }

    setDateBeginToday() {
        this.dateBegin = this.convertDateTimeToLocalizedString(new Date(), this.dateMaskAPI)
    }

    setDateBeginFromParent() {
        if(this.parent) {
            this.dateBegin = this.parent.dateBegin
        }
    }

    isAfterDateBegin(dateObjectOrString: Date|string) {
        if (this.dateBegin !== null) {
            const dateBegin = this.getDateObjectFromMask(this.dateBegin)
            return date.getDateDiff(dateObjectOrString, dateBegin) > 0
        }
        return false
    }

    isMoneyType(): boolean {
        return !isEmpty(this.amount)
    }

    isItemType(): boolean {
        return isEmpty(this.amount)
    }
}
