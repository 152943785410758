import { i18n } from 'src/boot/i18n';

export type Money = {
    amount: number | string // api will send value as string
    currency: string
    formatted: string
}

export class MoneyClass implements Money {
    amount: number;
    currency: string;

    constructor(amount: number = 0, currency: string = 'EUR') {
        this.amount = amount;
        this.currency = currency;
    }

    static fromServer(data: Money) {
        return new MoneyClass(
            Number(data.amount),
            data.currency,
        )
    }

    get formatted(): string {
        const locale = i18n.global.locale.value
        const numberFormat = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: this.currency,
        })
        const fractionLength = numberFormat.formatToParts(1)
            .find(part => part.type === 'fraction')
            ?.value.length ?? 0;

        // Divide the amount by 10^fractionLength to get the base unit value
        const baseUnitValue = this.amount / Math.pow(10, fractionLength);

        return numberFormat.format(baseUnitValue)
    }

    add(amount: number = 0) {
        return new MoneyClass(
            this.amount + amount,
            this.currency,
        )
    }
    subtract(amount: number = 0) {
        return new MoneyClass(
            this.amount - amount,
            this.currency,
        )
    }
    subtractFrom(money: Money) {
        return MoneyClass.fromServer(money).subtract(this.amount)
    }
}

