import { IncludeScope, SpraypaintBase } from 'spraypaint';
import { cloneDeep, isEmpty } from 'lodash';
import { ModelAttributeChangeSet } from 'spraypaint/lib/model';

export default class SpraypaintBaseNonPersistedDirtyTracking extends SpraypaintBase {

  // eslint-disable-next-line
  private attributesOnLastReset: Record<string, any>;

  /**
   * this function adds a workaround to take last call of #reset() as base to check for changes
   */
  reset() {
    this.attributesOnLastReset = cloneDeep(this.attributes);
    super.reset();
  }

  /**
   * default check marks all attributes from non persisted models as changed
   * this function adds a workaround to take last call of #reset() as base to check for changes
   * @param relationships
   */
  isDirty(relationships?: IncludeScope): boolean {
    if (this.isPersisted) {
      return super.isDirty(relationships);
    } else {
      // TODO add relationship tracking? Is it necessary for non persisted objects?
      return !isEmpty(this.changes());
    }
  }

  /**
   * default check marks all attributes from non persisted models as changed
   * this function adds a workaround to take last call of #reset() as base to check for changes
   */
  changes(): ModelAttributeChangeSet<never> {
    if (this.isPersisted) {
      return super.changes();
    } else {
      const dirty = {};
      for (let _i = 0, _a = Object.keys(this.attributes); _i < _a.length; _i++) {
        const key = _a[_i];
        const current = this.attributes[key];
        const attrDef = this.klass.attributeList[key];
        // console.log('check: ', key, prior, current)
        if (this.attributesOnLastReset === undefined) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          dirty[key] = [null, current];
        } else {
          const prior = this.attributesOnLastReset[key];
          if (attrDef.dirtyChecker(prior, current)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dirty[key] = [prior, current];
          }
        }

      }
      return dirty;
    }
  }
}
