import { Attr, HasMany, HasOne, Model, SortScope } from 'spraypaint';
import BaseModel from 'src/models/BaseModel';
import TeamMember from 'src/models/TeamMember';
import TeamRole from 'src/models/TeamRole';
import { useAuthStore } from 'src/stores/auth';
import { isEmpty, orderBy } from 'lodash';
import Invite, { Invitable } from 'src/models/Invite';
import TeamEventTaskConfig from 'src/models/TeamEventTaskConfig';
import TeamStatsRange from 'src/models/TeamStatsRange';
import TeamLedger from 'src/models/TeamLedger';

@Model()
export default class Team extends BaseModel implements Invitable<Team> {
  static jsonapiType = 'teams';

  @Attr() id: string;
  @Attr() name: string;
  @Attr() iconName: string;
  @Attr() membersCount: number;
  @Attr() registeredMembersCount: number;
  @HasMany('teamMembers') members: TeamMember[] = [];
  @HasMany() private roles: TeamRole[] = [];
  @HasOne() invite?: Invite<Team>;
  @HasMany() eventTaskConfigs: TeamEventTaskConfig[] = [];
  @HasMany() statsRanges: TeamStatsRange[] = [];
  @HasMany() readonly activeStatsRanges: TeamStatsRange[] | null = null;
  @HasOne(TeamLedger) readonly ledger: TeamLedger | null = null;

  @Attr() readonly isSubscribed: boolean;
  @Attr() readonly isOnTrial: boolean;
  @Attr() private readonly trialEndsAt: string;

  hasTrialEndDate(): boolean {
    return !isEmpty(this.trialEndsAt)
  }

  getTrialEndsAtFormatted(): string {
    return this.convertDateStringFromApi(this.trialEndsAt);
  }

  get activeStatsRange(): TeamStatsRange | null {
    return (this.activeStatsRanges && this.activeStatsRanges.length > 0) ? this.activeStatsRanges[0] : null;
  }

  get membersSortedByName(): TeamMember[] {
    return Team.orderMembersByName(this.members);
  }

  get activeMembersSortedByName(): TeamMember[] {
    return Team.orderMembersByName(this.getMembers());
  }

  get membersSortedByStatusRoleAndName(): TeamMember[] {
    return Team.orderMembersBy(this.members, [
      { statusRole: 'asc' },
      { name: 'asc' },
    ]);
  }

  static orderMembersBy(members: TeamMember[], sortOptions: SortScope[]) {

    const iteratees = ['isPersisted'];
    const orders: Array<'asc' | 'desc'> = ['desc'];

    for (const sortOption of sortOptions) {
      const iteratee = Object.keys(sortOption)[0];
      const order = sortOption[iteratee];
      if (iteratee === 'statusRole') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iteratees.push(function(member: TeamMember) {
          return TeamRole.statusRoleOrder.indexOf(member.statusRole.name);
        });
      } else if (iteratee === 'name') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        iteratees.push(function(member: TeamMember) {
          return member.name?.toLowerCase();
        });
      } else {
        iteratees.push(iteratee);
      }
      orders.push(order);
    }

    return orderBy(members, iteratees, orders);
  }

  static orderMembersByName(members: TeamMember[]) {
    return Team.orderMembersBy(members, [{ name: 'asc' }]);
  }

  hasPersistedInvite(): boolean {
    return this.invite !== undefined && this.invite.isPersisted;
  }

  getAssignedMembers(includeInactive = false): TeamMember[] {
    const members = useAuthStore().person?.teamMembers
      .filter(member => {
        if (!includeInactive && member.statusRole.name === 'inactive') {
          return false;
        } else {
          return member.team.id == this.id;
        }
      }) as TeamMember[];
    return Team.orderMembersByName(members);
  }

  getMembers(includeInactive = false) {
    return this.members.filter((member) => {
      if (!includeInactive && member.statusRole.name === 'inactive') {
        return false;
      }
      return true;
    });
  }

  getMembersCount(includeInactive = false): number {
    return this.getMembers(includeInactive).length;
  }

  getMemberStatusRole(): TeamRole | undefined {
    return this.roles.find(teamRole => teamRole.name === 'member');
  }

  get statusRoles(): TeamRole[] {
      return this.roles.filter(role => role.isStatusRole());
  }

  get customRoles(): TeamRole[] {
      return this.roles.filter(role => !role.isStatusRole());
  }
}
